// ==========================================================================
// Website or App Stylesheet
// ==========================================================================

// Import Base & Modules
@import "../../node_modules/@getbase/animations/scss/index";
@import "../../node_modules/@getbase/base/scss/index";
@import "../../node_modules/@getbase/buttons/scss/index";
@import "../../node_modules/@getbase/containers/scss/index";
@import "../../node_modules/@getbase/forms/scss/index";
@import "../../node_modules/@getbase/grid/scss/index";
// @import "../../node_modules/@getbase/micro-grid/scss/index";
@import "../../node_modules/@getbase/tables/scss/index";
@import "../../node_modules/@getbase/typography/scss/index";

// Base Typography and Layout Helpers
@import "../../node_modules/@getbase/typography-helpers/scss/index";
@import "../../node_modules/@getbase/layout-helpers/scss/index";
@import "../../node_modules/@getbase/layout-spacers/scss/index";

body {
  margin: 0 0 .5em 0;
  font-size: 14px;
  line-height: 24px;
  color: #555;
  font-weight: 300;
}

* {
  font-family: Verdana, sans-serif, cursive !important;
}

.b-header {
  display: flex;
  min-height: 100px;
  align-items: center;
}

.b-menu {
  li {
    list-style: none;
  }

  a {
    text-decoration: none;
    padding: 10px 0;
    position: relative;
    font-size: 13px;
    line-height: 16px;
    font-weight: bold;

    &:hover:after, &.active:after {
      left: 0;
      width: 100%;
    }

    &:after {
      transition: all .1s;
      width: 0;
      height: 3px;
      background: #fe504f;
      content: "";
      display: block;
      bottom: 0;
      left: 50%;
      position: absolute;
    }
  }
}

.b-footer {
  background: black;
  color: white;
  line-height: 40px;
  opacity: .8;
  margin-top: 60px;
}

.b-title {
  font-weight: bold;
  line-height: 60px;
}

.b-interview {
  .fs-6 {
      font-weight: bold;
      position: relative;

      &:after {
          content: "";
          width: 60px;
          height: 3px;
          background: #fe504f;
          margin-top: 4px;
          margin-bottom: 5px;
          display: block;
          margin-left: auto;
          margin-right: auto;
      }
  }

  h1 {
    width: 100px;
    border-bottom: 3px solid #fe504f;
  }
}

.title {
  cursor: pointer;
}

.b-photo {
  &__gallery {
    display: flex;
    flex-wrap: wrap;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    image-orientation: from-image;
  }

  .row {
    margin-bottom: 20px;
  }
}

.hidden {
  display: none;
}